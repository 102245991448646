.perfo_top_container {
  height: calc(100vh - 60px);
  width: 100%;
}

.perfo_top_wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.perfo_top_left {
  flex: 6;
  display: flex;

  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.perfo_top_left_title {
  font-size: 20px;
}

.perfo_top_left_title_varies {
  background-color: gray;
  width: 75px;
  height: 25px;
  overflow: hidden;
  font-size: 15px;
  color: white;
  margin: 10px 0px;
}

.perfo_top_left_title_vary {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  animation:updown 10s infinite;
}

@keyframes  updown{
  0% {
    transform: translateY(0px);
  }
  20% {
    transform: translateY(-28px);
  }
  40% {
    transform: translateY(-56px);
  }
  60% {
    transform: translateY(-84px);
  }
  80% {
    transform: translateY(-111px);
  }
  100% {
    transform: translateY(0px);
  }
}

.perfo_top_left_des {
  width: 80%;
  margin: 0px auto;
}

.perfo_top_left_para {
  font-size: 15px;
  line-height: 25px;
  font-weight: 300px;
  text-indent: 20px;
}

.perfo_top_right {
  flex: 6;

  display: flex;
  justify-content: center;
  align-items: center;
}

.perfo_top_right_photo {
  width: 70%;
  height: 70%;
  border-radius: 50%;
}

.perfo_top_right_img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

@media (max-width: 760px) {
  .perfo_top_wrapper {
    flex-direction: column;
  }

  .perfo_top_left_title {
    font-size: 20px;
  }
}
