.nav_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav_left_title {
  margin-left: 15px;
  font-weight: 300px;
  font-size: 20px;
  cursor: pointer;
}

.nav_drawer_items {
  display: none;
}

.nav_right {
  display: flex;
}

.nav_right_items {
  display: flex;
  margin-right: 10px;
}

.nav_right_item {
  list-style: none;
  margin-right: 15px;
  font-weight: 300px;
  transition: all 0.5s ease;
  cursor: pointer;
  padding: 1px;
}

.nav_right_item:hover {
  transform: scale(1.1);
}

a {
  text-decoration: none;
  color: green;
  font-weight: bold;
}

@media (max-width: 760px) {
  .nav_right_items {
    display: none;
  }

  .nav_left_title {
    font-size: 15px;
  }

  .nav_drawer_items {
    display: flex;
    font-size: 15px;
    margin-right: 15px;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }

  .nav_drawer_item {
    list-style: none;
    margin-right: 18px;
    animation: appear 0.8s ease;
  }

  @keyframes appear {
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  }

  .nav_drawer_item:hover {
    transform: scale(1.1);
    border-bottom: 1px solid blue;
  }
}
