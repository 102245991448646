.progress_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: black;
}

.progress_bar {
  width: 400px;
  height: 3vh;
  color: white;
  display: flex;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
}

.bar {
  background-color: blue;
  display: flex;
  justify-content: stretch;
  align-items: start;
  width: 0%;

  height: 100%;
}

.percent {
  position: absolute;
  width: 50px;
  margin: auto;
  left: 0;
  right: 0;
  padding: 2px;
}
